import React from 'react';
import { Link } from 'react-router-dom';
import Utils from "../../../js/utils";

const NoAS = (props) => {
    // register as
    const data = Utils.formApiData('/requsted/'+props.asNum, true, 'json');
    Utils.performGETcall(data);
    return (
        <div className={'main-panel'}>
            <div className='content-panel panel__no-as'>
                <Link className='home' to='/'>
                    <img alt='cocopacket-logo' id='icon-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                </Link>
                <div className='as-info-panel'>
                    <span>{props.asNum}</span>
                    <p>
                        We appologize but this AS is not yet added to our system.
                        <br/>
                        <br/>
                        We monitor all the requests and we try to add frequently visited AS that are not in the system.
                    </p>
                    <Link className='' to='/'>
                        Go home
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NoAS;
