import React from 'react';
import SVGInline from 'react-svg-inline';
import Utils from '../../js/utils.js';
import iconChevronDown from '../../../less/libs/icons/chevron_down.svg';

class TopPanel extends React.PureComponent {

    getLogo = () => {
        const label = Utils.getPropertyOfObject(['theme', 'label'], this.props);
        let imgSrc = (label === 'light') ? '/less/img/cocopacket-logo.png' : '/less/img/cocopacket-logo_inverse.svg';
        return (
            <a className='home' href='/'>
                <img id='icon-logo' src={imgSrc} />
            </a>
        );
    }

    getIpList = () => {
        const sidePanelStatus = Utils.getPropertyOfObject(['sidePanelStatus'], this.props);
        const triggerSidebarAction = Utils.getPropertyOfObject(['triggerSidebarAction'], this.props);
        const direction = sidePanelStatus ? 'down' : 'up';
        return (
            <button className='ip-list' onClick={triggerSidebarAction}>
                <span className='ip-list--left'>List</span>
                <span className='ip-list--right'>
                    <SVGInline svg={iconChevronDown} className={`chevron ${direction}`} />
                </span>
            </button>
        );
    }

    render() {
        return (
            <div className='top-panel'>
                <div className='left'>
                    {this.getLogo()}
                    {/*{this.getIpList()}*/}
                </div>
            </div>
        );
    }
}

export default TopPanel;
