/* global $:true */
import React from 'react';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils.js';

class InnerGraphHeaderMobile extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.spanGraphTypeClick = this.spanGraphTypeClick.bind(this);
        this.onChangeHandlerGraphType = this.onChangeHandlerGraphType.bind(this);
        this.changeGraphType = this.changeGraphType.bind(this);
        this.getSwitches = this.getSwitches.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
        };
    }

    spanGraphTypeClick(e) {
        const type = e.target.getAttribute('data-value');
        this.changeGraphType(type);
    }

    onChangeHandlerGraphType() {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeGraphType(type);
    }

    changeGraphType(type) {
        this.setState({
            graphType: type,
        });
        this.props.setGraphType(type);
    }

    getSwitches() {
        const isSmoke = this.state.graphType == 'advanced';
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} data-value='basic' onClick={this.spanGraphTypeClick}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} data-value='advanced' onClick={this.spanGraphTypeClick}>
                        Smoke
                    </span>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        return (
            <div className='panel'>
                {this.getSwitches()}
            </div>
        );
    }
}

export default InnerGraphHeaderMobile;
