/* global $:true */

import React, {useState} from 'react';
import Utils from '../../../js/utils.js';
import As from './as.jsx';
import Search from './search.jsx';

const IpSidebar = (props) => {
    const data = Utils.getPropertyOfObject(['config','groups'], props, []);
    const [filtered, setFiltered] = useState(null);
    const getContent = () => {
        const source = (filtered !== null) ? filtered : Object.keys(data);
        const keys = source.map(key => key.split('->')[0]) ;
        let content = [];
        keys.forEach((key,i) => {
            const as = key.split('-')[0];
            let title = key.toLowerCase();
            title = title.replace(/^as{1}/, 'AS');
            const ips = Utils.getPropertyOfObject(['structure',as], props, []);
            if(ips.length === 0) { return; }
            content.push(
                <As
                    key={as+'_'+i}
                    ips={props.structure[as]}
                    as={as}
                    asTitle={title}
                    colorList={props.colorList}
                />
            );
        });
        if(content.length === 0){
            content = (<li key="no-data">no results</li>);
        };
        return (
            <ul className='top-ul'>
                {content}
            </ul>
        );
    };
    return (
        <div id={'sidebar'}>
            <Search data={data} setFiltered={setFiltered}/>
            {getContent()}
        </div>
    );
}
export default IpSidebar;