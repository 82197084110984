import React from 'react';
import UrlWrapper from './urlwrapper.jsx';
import TopPanel from "../topPanel/topPanel.jsx";
import SidePanel from "../sidePanel/sidePanel.jsx";
import ControlPanel from '../graph/header/controlPanel.jsx';
import GraphContainer from '../graph/graphContainer.jsx';
import Utils from "../../js/utils";
import Clipboard from 'clipboard';
import ASInfo from '../graph/asInfo.jsx';


class MainPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const ipInfo = Utils.getPropertyOfObject(['graph'], this.props, {});
        const asNumProp = Utils.getPropertyOfObject(['asNum'], this.props, undefined);
        const selectedIpProp = Utils.getPropertyOfObject(['selectedIp'], this.props, undefined);
        const { selectedIp, asNum } = this.setUnsetSource(asNumProp, selectedIpProp);
        const ipDetails = {...ipInfo};
        this.fixMissingProperties(selectedIp, ipDetails);
        this.isNarrow = window.innerWidth <= 1000;
        return {
            sidePanelStatus: !this.isNarrow,
            view: Utils.isSet(ipInfo) ? 'graph' : 'info',
            selectedIp,
            asNum,
            ipDetails
        };
    }

    setUnsetSource = (asNum, selectedIp) => {
        const isAsSet = Utils.isSet(asNum);
        const isIpSet = Utils.isSet(selectedIp);
        if(isAsSet && isIpSet){return {selectedIp, asNum}}
        if(isAsSet && !isIpSet){
            const structure = Utils.getPropertyOfObject(['structure'], this.props, undefined);
            return { selectedIp:structure[asNum][0], asNum }
        }
        const structure = Utils.getPropertyOfObject(['structure'], this.props, undefined);
        const as = Object.keys(structure)[0];
        const ip = structure[as][0];
        return {selectedIp: ip, asNum: as}
    }

    updateIp = (selectedIp) => this.setState({selectedIp});

    fixMissingProperties = (ip, graph) => {
        if (Utils.isNotSet(graph.probe)) {
            const slaves = Utils.getPropertyOfObject(['config','ping','ips', ip, 'slaves'], this.props);
            graph.probe = `slave=${slaves[0]}`;
        }
        if (Utils.isNotSet(graph.endPoint)) { graph.endPoint = Utils.getTimeNow(); }
        if (Utils.isNotSet(graph.graphType)) { graph.graphType = 'basic'; }
        if (Utils.isNotSet(graph.accuracy)) { graph.accuracy = 'normal'; }
        if (Utils.isNotSet(graph.epoch)) { graph.epoch = 86400; }
        if (graph.epoch < 60) { graph.epoch = 60; }
        return graph;
    }

    setIpDetails = (list) => {
        const ipDetails = {...this.state.ipDetails};
        Object.keys(list).forEach(key => ipDetails[key] = list[key]);
        this.setState({ ipDetails });
    }

    setEpochWithEndpoint = (epoch, endPoint, customEpoch) => {
        epoch = epoch < 60 ? 60 : epoch;
        this.setIpDetails({ customEpoch, epoch, endPoint });
    }

    copyASAction = (e) => {
        const scope = this;
        const selector = $(this.refs['as-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.state.asNum);
            selector.css('color', color);
        }, 2000);
    }

    getMobileSearchBox = (props) =>  ( <Search {...props} /> );

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.asNum != nextProps.asNum){
            const asNumProp = Utils.getPropertyOfObject(['asNum'], nextProps, undefined);
            const selectedIpProp = Utils.getPropertyOfObject(['selectedIp'], nextProps, undefined);
            const { selectedIp, asNum } = this.setUnsetSource(asNumProp, selectedIpProp);
            this.setState({selectedIp, asNum });
        }
    }

    componentDidMount() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        const { isMobile, structure, config, changeColor, theme, colorList } = this.props;
        const { sidePanelStatus, selectedIp, asNum, ipDetails } = this.state;

        const ipInfo = Utils.getPropertyOfObject(['config','ping','ips', selectedIp], this.props);
        const probesList = Utils.getPropertyOfObject(['slaves'], ipInfo);
        const title = Utils.getPropertyOfObject(['cat', 0], ipInfo).split('-')[1];

        // TOP - PANEL - COMPONENT
        const topPanelParams = { config, sidePanelStatus, structure, theme };
        const componentTopPanel =  !isMobile ?
            <TopPanel {...topPanelParams} triggerSidebarAction={()=>this.setState((prev)=>({sidePanelStatus: !prev.sidePanelStatus}))}/> :
            <></>;
        // SIDE - PANEL - COMPONENT
        const sidePanelParams = { config, sidePanelStatus, structure, colorList};
        const componentSidePanel = (!isMobile && sidePanelStatus) ? (<SidePanel {...sidePanelParams} />) : <></>;
        // CONTROL - PANEL - COMPONENT
        const controlPanelParams = { config, sidePanelStatus, structure, changeColor, theme, selectedIp, asNum };
        const componentControlPanel = !isMobile ? (<ControlPanel {...controlPanelParams} updateIp={this.updateIp}/>) : <></>;
        const view = this.state.view;
        return (
            <UrlWrapper
                {...this.props}
                state={this.state}
                setUrl={(activeUrl)=>this.setState({ activeUrl })}
            >
                {componentTopPanel}
                <div className={`main-panel ${sidePanelStatus ? 'side-relative' : ''}`}>
                    { sidePanelStatus && componentSidePanel}
                    <div className='content-panel'>
                        {!isMobile &&
                        <div className='as-info-panel--main'>
                            <a onClick={this.copyASAction} className={'as__child'}>
                                <span
                                    ref='as-title'
                                    data-clipboard-text={asNum}
                                    className={`ip ip-info copy-btn`}>
                                    {asNum}
                                </span>
                            </a>
                            <span className={'as__child'}>
                                {title}
                            </span>
                            <div className={'as__child as__child--icons'}>
                                <a className={'as-info-panel__icon ' + (view === 'info' ? 'active' : '') }
                                   onClick={() => this.setState({view: 'info'})}>
                                    <i className={'fa fa-info-circle'} />
                                </a>
                                <a className={'as-info-panel__icon ' + (view === 'graph' ? 'active' : '') }
                                   onClick={() => this.setState({view: 'graph'})}
                                >
                                    <i className={'fa fa-bar-chart'} />
                                </a>
                            </div>
                        </div>
                        }
                        { view === 'graph' &&
                            <>
                                {componentControlPanel}
                                <GraphContainer
                                    ref='graph'
                                    switchView={() => this.setState({view: 'info'})}
                                    {...this.props}
                                    ipDetails={ipDetails}
                                    isNarrow={sidePanelStatus}
                                    probesList={probesList}
                                    selectedIp={selectedIp}
                                    asTitle={title}
                                    asNum={asNum}
                                    // setters
                                    setActiveProbe={(probe) => this.setIpDetails({ probe })}
                                    setGraphType={(graphType) => this.setIpDetails({ graphType })}
                                    setAccuracyType={(accuracy) => this.setIpDetails({ accuracy })}
                                    setEpochWithEndpoint={this.setEpochWithEndpoint}
                                    setEpochFromZoom={this.setEpochWithEndpoint}
                                />
                            </>
                        }
                        { view === 'info' &&
                        <ASInfo key={asNum}
                                asNum={asNum}
                                switchView={() => this.setState({view: 'graph'})}
                                asList={Object.keys(structure)}
                                isMobile={isMobile}
                                {...this.props}
                        /> }
                    </div>
                </div>
            </UrlWrapper>
        );
    }
}

export default MainPanel;
