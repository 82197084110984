/* global $:true */
import React from 'react';
import * as Sentry from '@sentry/browser';
import Utils from '../js/utils';
import MainPanel from './mainPanel/mainPanel.jsx';
import DefaultError from './error/defaultError.jsx';
import NoAS from './mainPanel/noAs/mainPanelNoAs.jsx'


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        theme: this.getDarkTheme(),
        config: {},
        slaveList: {},
        colorList: [],
        structure: {},
        hasError: false
    })

    getLightTheme = () => ({
        label: 'light',
        colorBckgr: '#FFFFFF',
        colorText: '#C9CBD6',
        shadowReverse: true,
        sideText: '#5A5D70',
        simpleLine: 'rgba(0,0,0,0.5)',
    })

    getDarkTheme = () => ({
        label: 'dark',
        colorBckgr: '#3F4155',
        colorText: '#474C60',
        shadowReverse: false,
        sideText: '#9496A6',
        simpleLine: 'rgba(200,200,200,0.6)',
    })

    loadSlaveList = () => { // API CALL GET
        const successCallback = (data) => this.setState({ slaveList: data });
        const data = Utils.formApiData('/slaves', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getIpsColor = () => { // API CALL GET
        const now = Math.ceil(Date.now() / 1000);
        const from = now - 86400;
        const url = `/getlost/${from}/${now}`;
        const successCallback = (data) => this.setState({ colorList: data.ping });
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    loadInitialConfig = () => { // API CALL GET
        const createStructure = (response) => {
            let data = Utils.getPropertyOfObject(['ping','ips'], response, []);
            const ips = Object.keys(data);
            const structure = {};
            ips.forEach((ip) => {
                const local = data[ip];
                let { cat } = local;
                cat = cat[0].split('-')[0]; // ['AS123->'] --> AS123
                if ( Utils.isSet(structure[cat])){
                    structure[cat].push(ip);
                } else {
                    structure[cat] = [ip];
                }
            });
            let groupData = Utils.getPropertyOfObject(['groups'], response, []);
            let groupKeys = Object.keys(groupData);
            const dictionary = {};
            groupKeys.forEach(key => {
                let arr = key.split('-');
                let asKey = arr[0];
                arr[0] = ""; arr[arr.length-1]="";
                let name = arr.join('');
                dictionary[asKey] = name ;
            });
            this.setState({ config:response, structure, dictionary });
        };
        const successCallback = (response) => createStructure(response);
        const data = Utils.formApiData('/config', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    changeColor = (themeLabel) => {
        const theme = (themeLabel === 'light') ? this.getLightTheme() : this.getDarkTheme();
        this.setState({ theme });
    }

    findAS = (asNumProp) => {
        if(asNumProp){
            const structure = Utils.getPropertyOfObject(['structure'], this.state, []);
            return Object.keys(structure).some( key => key === asNumProp );
        }
        return true;
    }

    componentWillMount() {
        this.initSentry();
        this.initMainState();
    }

    initSentry() {
        Sentry.init({dsn: "https://a5b225a47fdb4e198246b4538226686d@sentry.io/1953978"});
    }

    initMainState = () => {
        this.getIpsColor();
        this.loadInitialConfig();
        this.loadSlaveList();
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        const data = Utils.getPropertyOfObject(['config'], this.state);
        const structure = Utils.getPropertyOfObject(['structure'], this.state);
        if (this.state.hasError) { return (<DefaultError isMobile={this.props.isMobile} />); }
        if (Utils.isNotSet(data) || Utils.isNotSet(structure)) { return (<div id='mother' />); }
        const asNumProp = Utils.getPropertyOfObject(['asNum'], this.props, null);
        const isASin = this.findAS(asNumProp);
        return (
            <>
                {this.props.children}
                <div id='father' className={`theme-${this.state.theme.label}`}>
                    {!isASin && <NoAS asNum={asNumProp} />}
                    {isASin &&
                        <MainPanel
                            {...this.props}
                            {...this.state}
                            changeColor={this.changeColor}
                        />
                    }
                </div>
            </>
        );
    }
}

export default Main;


