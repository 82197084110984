import React from 'react';
import IpSidebar from './sidebar/ipSidebar.jsx';

const SidePanel = (props) => {
    const { sidePanelStatus } = props;
    const isHidden = sidePanelStatus ? '' : 'hidden';
    return (
        <div className={`side-panel ${isHidden}`}>
            <div className="tab-slider--nav">
                <ul className="tab-slider--tabs">
                    <li className={'tab-slider--trigger active'}>
                        Networks
                    </li>
                </ul>
            </div>
            <IpSidebar colorList={props.colorList} structure={props.structure} config={props.config}/>
        </div>
    );
}

export default SidePanel;
