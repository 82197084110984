/* global DataBasic:true, DataAdvanced:true */
import React from 'react';
import Utils from '../../js/utils.js';
import GraphHeader from './header/header.jsx';
import GraphHeaderMobile from './header/headerMobile.jsx';
import GraphFooter from './footer/graphFooter.jsx';
import Graph from './graph.jsx';
import InnerGraphHeader from './header/innerHeader.jsx';
import InnerGraphHeaderMobile from './header/innerHeaderMobile.jsx';
import TraceLegend from './footer/traceLegend.jsx';
import '../../js/dataBasic';
import '../../js/dataAdvanced';

class GraphContainer extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.loadGraph = this.loadGraph.bind(this);
        this.switchYAxis = this.switchYAxis.bind(this);
        this.getAgrCount = this.getAgrCount.bind(this);
        this.loadBasicGraph = this.loadBasicGraph.bind(this);
        this.loadAdvancedGraph = this.loadAdvancedGraph.bind(this);
        this.setGraphData = this.setGraphData.bind(this);
        this.getID = this.getID.bind(this);
        this.getHeader = this.getHeader.bind(this);
        this.getGraphFooter = this.getGraphFooter.bind(this);
        this.getInnerGraphHeader = this.getInnerGraphHeader.bind(this);
        this.getTrueTimeFrame = this.getTrueTimeFrame.bind(this);
        this.getTracePanel = this.getTracePanel.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            chart: null,
            graphData: null,
            yAxisLimit: null,
            stats: {},
            isUTC: false,
            zoomLevel: 1,
        };
    }

    setChartInstance = (key, data) => this.setState({ [key]: data });

    setUTC = () => this.setState( (prevState) => ({ isUTC: !prevState.isUTC }));

    loadGraph(data, ip, now, graphType) {
        if (graphType == undefined) {
            graphType = this.props.ipDetails.graphType;
        }
        if (now == undefined) {
            now = Utils.getTimeNow();
        }
        if (graphType == 'basic') {
            this.loadBasicGraph(data, ip, now);
        } else {
            this.loadAdvancedGraph(data, ip, now);
        }
    }

    switchYAxis() {
        const { chart } = this.state;
        const temp = chart.yAxis[0].max;
        const max = this.state.yAxisLimit;
        chart.yAxis[0].update({ max });
        this.setState({ yAxisLimit: temp });
    }

    getAgrCount(epoch, type) {
        const level = this.props.ipDetails.accuracy == 'normal' ? 0 : 1; // [low - 0, high - 1]
        const px = Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow);
        let agrCount; // smaller agrCount -> higher accuracy
        agrCount = epoch / px;
        if (level == 1) {
            agrCount = type == 'basic' ? 5 : agrCount;
        } else {
            agrCount = type == 'basic' ? agrCount : agrCount * 10;
        }
        return Math.ceil(agrCount);
    }

    loadBasicGraph(data, ip, now) {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'basic');
        const { probe } = data.ipDetails;
        const callback = this.setGraphData;
        DataBasic.loadData(ip, now, epoch, count, probe, callback);
    }

    loadAdvancedGraph(data, ip, now) {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'advanced');
        const { probe } = data.ipDetails;
        const callback = this.setGraphData;
        DataAdvanced.loadData(ip, now, epoch, count, probe, callback);
    }

    setGraphData(dataO, data, timestamp, stats) {
        let graphDataNew = data;
        if (this.props.ipDetails.graphType == 'advanced') {
            graphDataNew = DataAdvanced.getDisplayData(data, this.props.theme.shadowReverse);
        }
        this.setState({
            originalData: dataO,
            graphData: graphDataNew,
            stats,
        });
        if (Utils.isSet(this.props.setEndpointAndProbe)) {
            // this.props.setEndpointAndProbe(timestamp, slave);
        }
    }

    getID() {
        if (this.props.ipDetails.graphType == 'advanced') {
            return 'advanced-graph-wrap';
        }
        return 'basic-graph-wrap';
    }

    getHeader() {
        if (this.props.isMobile) {
            return (
                <GraphHeaderMobile
                    {...this.props}
                    graphData={this.state.graphData}
                />
            );
        }
        return (
            <GraphHeader
                {...this.props}
                chart={this.state.chart}
                switchYAxis={this.switchYAxis}
            />
        );
    }

    getGraphFooter() {
        return (
            <GraphFooter
                {...this.props}
                chart={this.state.chart}
                graphData={this.state.graphData}
                stats={this.state.stats}
                pxWidth={Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow)}
            />
        );
    }

    getInnerGraphHeader() {
        if (this.props.isMobile) {
            return (
                <InnerGraphHeaderMobile {...this.props} />
            );
        }
        return (
            <InnerGraphHeader
                {...this.props}
                setUTC={this.setUTC}
                isUTC={this.state.isUTC}
                setZoom={val => this.setChartInstance('zoomLevel', val)}
                zoomLevel={this.state.zoomLevel}
            />
        );
    }

    getTrueTimeFrame() {
        const data = this.state.graphData;
        const ipData = this.props.ipDetails;
        if (Utils.isSet(data) && Utils.isSet(data.epoch)) {
            return { to: ipData.endPoint, epoch: data.epoch };
        }
        return { to: ipData.endPoint, epoch: ipData.epoch };
    }

    setEpochFromZoom = (from, to, res = true) => {
        const epoch = to - from;
        this.props.setEpochFromZoom(epoch, to, res);
    }

    getTracePanel() {
        return (
            <TraceLegend
                trueTime={this.getTrueTimeFrame()}
                chart={this.state.chart}
                {...this.props}
            />
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ipDetails != this.props.ipDetails
         || prevProps.selectedIp != this.props.selectedIp
         || prevProps.theme != this.props.theme
         || prevProps.isNarrow != this.props.isNarrow) {
                this.loadGraph(this.props, this.props.selectedIp, this.props.ipDetails.endPoint);
         }
    }

    componentDidMount() {
        if (Utils.isSet(this.props.selectedIp)) {
            this.loadGraph(this.props, this.props.selectedIp, this.props.ipDetails.endPoint);
        }
    }

    render() {
        return (
            <div className='graph-wrap' id={this.getID()}>
                {this.getHeader()}
                <div className='graph-inner-wrap'>
                    {this.getInnerGraphHeader()}
                    <Graph
                        graphData={this.state.graphData}
                        originalData={this.state.originalData}
                        graphType={this.props.ipDetails.graphType}
                        ipDetails={this.props.ipDetails}
                        selectedIp={this.props.selectedIp}
                        setChartInstance={this.setChartInstance}
                        setEpochFromZoom={this.setEpochFromZoom}
                        incidentHighlight={this.props.incidentHighlight}
                        isMobile={this.props.isMobile}
                        theme={this.props.theme}
                        isUTC={this.state.isUTC}
                        chartWidth={Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow)}
                        zoomLevel={this.state.zoomLevel}
                    />
                    {this.getGraphFooter()}
                </div>
                {this.getTracePanel()}
            </div>
        );
    }
}

export default GraphContainer;
