/* global $:true, Chart:true */
import React from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts-multicolor-series';
import Utils from '../../js/utils.js';
import Loader from './loader.jsx';
import '../../js/chart.js';
import 'highcharts-boost';

Exporting(Highcharts);

class Graph extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getChartConfig = this.getChartConfig.bind(this);
        this.zoomSelection = this.zoomSelection.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.getEpochFromDataBasic = this.getEpochFromDataBasic.bind(this);
        this.getEpochFromDataAdvanced = this.getEpochFromDataAdvanced.bind(this);
        this.chart = null;
        this.loader = true;
    }

    getChartConfig() {
        const { props } = this;
        const { isUTC } = props;
        const end = props.ipDetails.endPoint;
        const { epoch } = props.ipDetails;
        const data = { ...props.graphData };
        data.epoch = epoch;
        data.start = (end - epoch) * 1000;
        data.color = props.theme;
        if (this.props.zoomLevel === 0) {
            data.yaxis = (props.graphType === 'basic') ? data.yaxis * 1.3 : null;
        }
        if (props.graphType === 'basic') {
            if (props.isMobile) {
                return Chart.getBasicChartConfigMobile(data);
            }
            return Chart.getBasicChartConfig(data, this.zoomSelection, isUTC, props.originalData, props.chartWidth);
        }
        if (props.isMobile) {
            return Chart.getAdvancedChartConfigMobile(data);
        }
        return Chart.getAdvancedChartConfig(data, this.zoomSelection, isUTC, props.originalData, props.chartWidth);
    }

    zoomSelection(event) {
        let from = event.xAxis[0].min / 1000;
        from = Number(from.toFixed(0));
        let to = event.xAxis[0].max / 1000;
        to = Number(to.toFixed(0));
        this.props.setEpochFromZoom(from, to);
        return false;
    }

    renderChart() {
        Highcharts.setOptions({ global: { useUTC: this.props.isUTC } });
        const node = this.refs.chartNode;
        const config = this.getChartConfig();
        this.chart = new Highcharts.chart(node, config);
        this.chart.render();
        if (!Utils.isSet(this.props.graphData.data)) {
            let { prompt } = this.props.graphData;
            prompt = typeof prompt === 'string' && prompt.length > 0 ? prompt.toUpperCase() : 'NO DATA';
            this.chart.showLoading(prompt);
        }
        this.props.setChartInstance('chart', this.chart);
    }

    getEpochFromDataBasic(data) {
        if (!Utils.isSet(data.data)) {
            return this.props.ipDetails.epoch;
        }
        const arr = data.data;
        const len = arr.length;
        const x1 = arr[0].x / 1000;
        const x2 = arr[len - 1].x / 1000;
        return x2 - x1;
    }

    getEpochFromDataAdvanced(data) {
        if (!Utils.isSet(data)) {
            return this.props.ipDetails.epoch;
        }
        const arr = data.data;
        const len = arr.length;
        const x1 = arr[0][0] / 1000;
        const x2 = arr[len - 1][0] / 1000;
        return x2 - x1;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.graphData != nextProps.graphData
            || this.props.isUTC != nextProps.isUTC
            || this.props.zoomLevel != nextProps.zoomLevel
            ) {
            this.loader = false;
            return true;
        }
        if (this.props.ipDetails != nextProps.ipDetails
                || this.props.selectedIp != nextProps.selectedIp
                || this.props.theme != nextProps.theme) {
            const { chart } = this;
            if (Utils.isSet(chart)) {
                this.loader = true;
                chart.destroy();
            }
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.graphData != this.props.graphData
           || prevProps.isUTC != this.props.isUTC
           || prevProps.zoomLevel != this.props.zoomLevel
           ) {
            const data = this.props.graphData;
            if (Utils.isSet(data)) {
                this.renderChart();
            }
        }
    }

    render() {
        return (
            <div>
                <div ref='chartNode' className='chart-container chart-container-load'>
                    {this.loader && <Loader />}
                </div>
            </div>
        );
    }
}

export default Graph;
