/* global $:true */
import React from 'react';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils.js';

class InnerGraphHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
            zoomLevel: this.props.zoomLevel,
            isUTC: this.props.isUTC

        };
    }

    onChangeHandlerGraphType = () => {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeGraphType(type);
    }

    onChangeHandlerChangeAccuracy = () => {
        const accuracy = this.state.accuracy == 'normal' ? 'high' : 'normal';
        this.changeGraphAccuracy(accuracy);
    }

    onChangeHandlerChangeZoom = () => {
        const zoom = this.state.zoomLevel === 0 ? 1 : 0;
        this.changeZoomLevel(zoom);
    }

    changeGraphType = (graphType) => {
        this.setState({ graphType });
        this.props.setGraphType(graphType);
    }

    changeGraphAccuracy = (accuracy) => {
        this.setState({ accuracy });
        this.props.setAccuracyType(accuracy);
    }

    changeZoomLevel = (zoomLevel) => {
        this.setState({ zoomLevel });
        this.props.setZoom(zoomLevel);
    }

    changeUTC = (isUTC) => {
        this.setState({ isUTC });
        this.props.setUTC(isUTC);
    }

    onChangeUTC = () => {
        const isUTC  = !this.state.isUTC;
        this.changeUTC(isUTC);
    }

    getZoomSwitch = () => {
        const { zoomLevel } = this.state;
        return (
            <div className="range-slider">
                <span className='fa fa-minus' onClick={() => this.changeZoomLevel(0)} />
                <input
                    type='range'
                    name="points"
                    min="0"
                    max='1'
                    onChange={this.onChangeHandlerChangeZoom}
                    value={zoomLevel}
                />
                <span className="fa fa-plus" onClick={() => this.changeZoomLevel(1)} />
            </div>
        );
    }

    getSwitches() {
        const isSmoke = this.state.graphType == 'advanced';
        const isHigh = this.state.accuracy == 'high';
        const { isUTC } = this.state;
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} onClick={() => this.changeGraphType('basic')}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} onClick={() => this.changeGraphType('advanced')}>
                        Smoke
                    </span>
                </div>
                <div className='switch'>
                    <span className={isHigh ? '' : 'active'} onClick={() => this.changeGraphAccuracy('normal')}>
                        Normal
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerChangeAccuracy} checked={isHigh} />
                        <span className='slider round' />
                    </label>
                    <span className={isHigh ? 'active' : ''} onClick={() => this.changeGraphAccuracy('high')}>
                        High
                    </span>
                </div>
                <div className='switch'>
                    <span className={isUTC ? '' : 'active'} onClick={() => this.changeUTC(false)}>
                        GMT
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeUTC} checked={isUTC} />
                        <span className='slider round' />
                    </label>
                    <span className={isUTC ? 'active' : ''} onClick={() => this.changeUTC(true)}>
                        UTC
                    </span>
                </div>
            </div>
        );
    }

    copyShareAction = (e) => {
        const selector = $(this.refs['share']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('Link Copied');
        setTimeout(() => {
            selector.text('Share');
            selector.css('color', color);
        }, 2000);
    }

    componentDidUpdate() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        const probeName = this.props.ipDetails.probe.split('=')[1];
        return (
            <div className='panel'>
                <p className={'text-white'}>
                    This graph represents latency and packet loss for a connection from
                    <strong> AS60068</strong> - <strong>{probeName + ' '}</strong>
                     to <strong>{this.props.asNum}</strong>.
                </p>
                <div className='controls-wrap'>
                    {this.getZoomSwitch()}
                    {this.getSwitches()}
                    <div className='share-ip'>
                        <a
                            className='cntrl-btn share-btn copy-btn'
                            onClick={this.copyShareAction}
                            data-clipboard-text={window.location.origin+''+window.activeUrl}
                        >
                            <span ref='share'>Share</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default InnerGraphHeader;
