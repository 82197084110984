import React from 'react';
import update from 'immutability-helper';
import { withTooltip } from 'react-tippy';
import Utils from '../../../js/utils.js';
import TraceTable from './traceTable.jsx';
import TraceTableMobile from './traceTableMobile.jsx';
import AsPath from './asPath.jsx';
import '../../../js/chart.js';

class TraceLegend extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.loadData = this.loadData.bind(this);
        this.analyzeData = this.analyzeData.bind(this);
        this.compareTraces = this.compareTraces.bind(this);
        this.setAsPath = this.setAsPath.bind(this);
        this.showGraphPoint = this.showGraphPoint.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getOptionChange = this.getOptionChange.bind(this);
        this.getOptionSort = this.getOptionSort.bind(this);
        this.changeOption = this.changeOption.bind(this);
        this.getLine = this.getLine.bind(this);
        this.genTimestamps = this.genTimestamps.bind(this);
        this.addTrace = this.addTrace.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
        this.setTraceOrder = this.setTraceOrder.bind(this);
        this.setSpecificTrace = this.setSpecificTrace.bind(this);
        this.getTraces = this.getTraces.bind(this);
        this.getLink = this.getLink.bind(this);
        this.showHideAllTraces = this.showHideAllTraces.bind(this);
        this.resize = this.resize.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isOpen: false,
            traceData: {},
            showAll: false,
            selection: {},
            selectionOrder: [],
            asPath: [],
            width: 0,
            sortByTime: false,
            showChanges: false,
            uniqueData: {},
        };
    }

    loadData(props) {
        const scope = this;
        const ip = props.selectedIp;
        const to = props.ipDetails.endPoint;
        const from = to - props.ipDetails.epoch;
        const url = `/traces/${ip}/${from}/${to}?${props.ipDetails.probe}`;
        // ----------------- API CALL GET -----------------
        const successCallback = function (data) {
            const tracesState = {};
            const order = [];
            const stampKeys = Object.keys(data);
            stampKeys.forEach((stamp, index) => {
                tracesState[stamp] = false;
            });
            if (scope.props.view == 'detail') {
                const lastStamp = stampKeys[stampKeys.length - 1];
                tracesState[lastStamp] = true;
                order.push(lastStamp);
            }
            const isOpen = Object.keys(data).length > 0;
            scope.setState({
                isOpen,
                traceData: data,
                selection: tracesState,
                selectionOrder: order,
                uniqueData: scope.analyzeData(data, 'unique'),

            });
            if (Utils.isSet(data)) {
                scope.setAsPath(data);
            }
        };
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    analyzeData(data) {
        const unique = {};
        let prev = {};
        const traceKeys = Object.keys(data);
        traceKeys.forEach((stamp) => {
            const trace = data[stamp];
            if (!this.compareTraces(prev, trace)) {
                unique[stamp] = trace;
            }
            prev = trace;
        });
        return unique;
    }

    compareTraces(prev, next) {
        if (Utils.isNotSet(prev) || Utils.isNotSet(next)) {
            return false;
        }
        if (prev.length != next.length) {
            const fixedPrev = this.eliminateStars(prev);
            const fixedNext = this.eliminateStars(next);
            if (fixedPrev.length == fixedNext.length) {
                return this.compareTraces(fixedPrev, fixedNext);
            }
            return false;
        }
        next.forEach((hop, index) => {
            const prevHop = prev[index][0];
            const nextHop = next[index][0];
            if (Utils.isSet(prevHop) && Utils.isSet(nextHop)) {
                if (prevHop.ip != nextHop.ip) {
                    return false;
                }
            }
        });
        return true;
    }

    eliminateStars(trace) {
        const { length } = Object.keys(trace);
        const newTrace = [trace[0]];
        let prev = trace[0][0]; // hop is * if == [] -> [0] -> undefined
        for (let i = 1; i < length; i++) {
            const hop = trace[i][0];
            if (Utils.isSet(hop) || Utils.isSet(prev)) {
                newTrace.push(trace[i]);
            }
            prev = hop;
        }
        return newTrace;
    }

    differenceInPerc(n1, n2) {
        const num1 = Number(n1);
        const num2 = Number(n2);
        return (Math.abs(num1 - num2) / ((num1 + num2) / 2)) * 100;
    }

    setAsPath(data) {
        const asPath = [];
        const asUniq = [];
        const stampKeys = Object.keys(data);
        const stamp = stampKeys[stampKeys.length - 1];
        const arrData = data[stamp];
        arrData.forEach((entry) => {
            let pl = 0;
            // this usually is one entry, but when it is not the PL has to be pre-calculated
            entry.forEach((as) => {
                pl += Number(as.received);
            });
            pl = 100 - pl;
            entry.forEach(({ as }) => {
                if (asUniq[asUniq.length - 1] !== as) {
                    asUniq.push(as);
                    asPath.push({ name: as, pl });
                }
            });
        });
        this.setState({ asPath });
    }

    showGraphPoint(e, val) {
        const { chart } = this.props;
        let { data } = chart.series[0];
        let { points } = chart.series[0];
        if (this.props.ipDetails.graphType === 'advanced') {
            data = chart.series[8].data;
            points = chart.series[8].points;
        }
        const index = this.findDataIndex(data, val);
        const selection = data[index];
        const xAxis = chart.xAxis[0];
        xAxis.drawCrosshair(e, points[index]);
        if (Utils.isSet(selection)) {
            selection.setState('hover');
            chart.tooltip.refresh(selection);
        }
    }

    getContent() {
        let data = this.state.traceData;
        if (!this.state.isOpen) {
            return '';
        }
        if (this.props.isMobile) {
            return (
                <div className='traceroute-detail'>
                    <div className='traces'>
                        {this.getTraces(data)}
                    </div>
                </div>
            );
        }
        data = this.state.showChanges ? this.state.uniqueData : this.state.traceData;
        return (
            <div className='traceroute-detail'>
                {this.getOptionSort()}
                <div className='traces'>
                    {this.getTraces(data)}
                </div>
            </div>
        );
    }



    getOptionChange() {
        return (
            <div className='traceroute-options'>
                <label className='inp-check inp-check--yell' htmlFor='selectAll'>
                    <input
                        type='checkbox'
                        id='selectAll'
                        checked={this.state.showAll}
                        onChange={e => this.showHideAllTraces()}
                    />
                    <span>Select all</span>
                </label>
                <label className='inp-check inp-check--yell' htmlFor='traceChanges'>
                    <input
                        type='checkbox'
                        id='traceChanges'
                        checked={this.state.showChanges}
                        onChange={e => this.changeOption('showChanges')}
                    />
                    <span>Show changes in route</span>
                </label>
            </div>
        );
    }

    getOptionSort() {
        return (
            <div className='traceroute-options'>
                <span className='bold'>Traceroute history</span>
                <label className='inp-check inp-check--yell' htmlFor='sortByTime'>
                    <input
                        type='checkbox'
                        id='sortByTime'
                        checked={this.state.sortByTime}
                        onChange={e => this.changeOption('sortByTime')}
                    />
                    <span>Sort by time</span>
                </label>
            </div>
        );
    }

    changeOption(option) {
        this.setState(prevState => ({ [option]: !prevState[option] }));
    }

    getLine() {
        if (this.props.isMobile) return [];
        const style = { width: this.state.width };
        return (
            <div className='legend-line-wrap'>
                <div className="legend-line-background">
                    <div className='legend-line' style={style}>
                        {this.genTimestamps()}
                    </div>
                </div>
            </div>
        );
    }

    genTimestamps() {
        const stamps = [];
        const { trueTime } = this.props;
        const length = trueTime.epoch;
        const from = trueTime.to - trueTime.epoch;
        const pixels = this.state.width;
        const step = pixels / length;
        const data = this.state.selection;
        Object.keys(data).forEach((stamp) => {
            if (!this.state.showChanges || (this.state.showChanges && (stamp in this.state.uniqueData))) {
                let className = data[stamp] ? 'active' : '';
                className += ' trace-record';
                const left = Number((stamp - from) * step);
                const style = { left: Number.isNaN(left) ? 0 : left };
                stamps.push(
                    <a
                        style={style}
                        className={className}
                        key={stamp}
                        data-value={stamp}
                        onMouseOver={e => this.showGraphPoint(e, stamp)}
                        onClick={this.addTrace}
                    />,
                );
            }
        });
        return stamps;
    }

    addTrace(e) {
        const val = e.target.getAttribute('data-value');
        const bool = this.state.selection[val];
        this.setSpecificTrace(val, !bool);
        this.setTraceOrder(val, !bool);
    }

    changeSelection() {
        this.setState(prevState => ({ selection: prevState.selection + 1 }));
    }

    setTraceOrder(time, val) {
        this.setState((prevState) => {
            const order = prevState.selectionOrder;
            if (val) {
                order.push(time);
            } else {
                const index = order.indexOf(time);
                if (index !== -1) order.splice(index, 1);
            }
            return { selectionOrder: order };
        });
    }

    setSpecificTrace(time, val) {
        const newState = update(this.state, {
            selection: { [time]: { $set: val } },
        });
        this.setState(newState);
    }

    getTraces(data) {
        const tracesActive = [];
        const byTime = this.state.sortByTime;
        const traces = byTime ? this.state.selection : this.state.selectionOrder;
        const arrData = byTime ? Object.keys(traces) : traces;
        arrData.forEach((trace, index) => {
            const traceTime = trace;
            const addTrace = byTime ? traces[trace] : true;
            if (addTrace && Utils.isSet(data[traceTime])) {
                if (this.props.isMobile) {
                    tracesActive.push(<TraceTableMobile time={traceTime} data={data[traceTime]} key={traceTime} />);
                } else {
                    tracesActive.push(<TraceTable time={traceTime} data={data[traceTime]} key={traceTime} />);
                }
            }
        });
        return tracesActive;
    }

    getLink() {
        const text = this.state.isOpen ? 'Hide Traceroute' : 'Show Traceroute';
        return (
            <a className='show-trace-option' onClick={this.changeDisplayState}>
                {text}
            </a>
        );
    }

    findDataIndex(data, val) {
        for (let i = 0; i < data.length; i++) {
            const value = data[i];
            if (Utils.isSet(value) && value.x / 1000 > val) {
                return i - 1;
            }
        }
    }

    showHideAllTraces() {
        this.setState((prevState) => {
            const action = !prevState.showAll;
            const traces = JSON.parse(JSON.stringify(prevState.selection));
            const order = [];
            const stamps = Object.keys(traces);
            stamps.forEach((trace) => {
                traces[trace] = action;
                if (action)order.push(trace);
            });
            return {
                selection: traces,
                selectionOrder: order,
                showAll: action,
            };
        });
    }

    changeDisplayState(props = this.props) {
        this.loadData(props);
    }

    resize() {
        this.updateDimensions();
    }

    updateDimensions(props = this.props) {
        this.setState({ width: Utils.getChartLineWidth(false, props.isNarrow) });
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        if (Utils.isSet(this.props.ipDetails) && Utils.isSet(this.props.selectedIp)) {
            this.changeDisplayState();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.isNarrow != nextProps.isNarrow) {
            this.updateDimensions(nextProps);
        }
        if (this.state != nextState) {
            return true;
        }
        if (this.props.trueTime.epoch != nextProps.trueTime.epoch
            || this.props.trueTime.to != nextProps.trueTime.to
            || this.props.ipDetails != nextProps.ipDetails
            || this.props.selectedIp != nextProps.selectedIp
        ) {
            this.changeDisplayState(nextProps);
        }
        return false;
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '0px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        tooltipOptions.title = 'Select a traceroute to show details';
        const TraceTooltip = withTooltip(InfoIcon, tooltipOptions);
        return (
            <div className='traceroute-legend'>
                <div className='title-head'>
                    <div className="title-head__top">
                        {!this.props.isMobile
                        && (
                            <div className='left'>
                                <span className='bold'>Traceroute</span>
                                <TraceTooltip />
                            </div>
                        )
                        }
                        <AsPath asPath={this.state.asPath} />
                    </div>
                    {!this.props.isMobile && this.getOptionChange()}
                    {this.getLine()}
                </div>
                {this.getContent()}
            </div>
        );
    }
}

export default TraceLegend;
