import React from 'react';
import Select2 from 'react-select2-wrapper';
import SVGInline from 'react-svg-inline';
import Utils from '../../../js/utils.js';
import iconChevronDown from '../../../../less/libs/icons/chevron_down.svg';

class GraphProbe extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.createProbes = this.createProbes.bind(this);
        this.createDropdowns = this.createDropdowns.bind(this);
        this.showAllProbes = this.showAllProbes.bind(this);
        this.sortAndFindTheBest = this.sortAndFindTheBest.bind(this);
        this.createTabs = this.createTabs.bind(this);
        this.probeChangeSelect = this.probeChangeSelect.bind(this);
        this.probeChange = this.probeChange.bind(this);
        this.getShowAllLink = this.getShowAllLink.bind(this);
        this.sideButtonAction = this.sideButtonAction.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            activeProbe: this.props.activeProbe.split('=')[1].toUpperCase(),
            isOpen: false,
            showInTabs: true,
            width: 0,
        };
    }

    createProbes() {
        let data = this.props.probesList;
        const count = Object.keys(data).length;
        data = data.sort();
        if (this.state.showInTabs) {
            return this.createTabs(data, count);
        }
        return this.createDropdowns(data, count);
    }

    createDropdowns(data, count) {
        const dataOptions = [];
        data.forEach((probe) => {
            probe = probe.toUpperCase();
            dataOptions.push(
                { text: probe, id: probe },
            );
        });
        const options = { minimumResultsForSearch: Infinity };
        const { activeProbe } = this.state;
        return (
            <div className='select-probe-wrap'>
                <span>
                    {' '}
                    { `${count} SLAVES: `}
                    {' '}
                </span>
                <Select2
                    options={options}
                    className='probes-select'
                    data={dataOptions}
                    onSelect={this.probeChangeSelect}
                    defaultValue={activeProbe.toUpperCase()}
                />
            </div>
        );
    }

    showAllProbes() {
        this.props.handleDetailSwitch('probes');
    }

    sortAndFindTheBest(original) {
        if (Utils.isNotSet(original)) {
            return [];
        }
        const data = JSON.parse(JSON.stringify(original));
        delete data.AVERAGE; delete data.SUM;
        const keys = Object.keys(data).sort((a, b) => data[a] - data[b]); // SORT
        let iterator = 0;
        while (data[keys[iterator]] < 0) {
            iterator += 1;
        } // iterate over all probes that are disconnected (have a value '-1'), the data are numerically sorted
        const winners = [];
        if (iterator < keys.length) { // it can happen that ip has no probe or all probes are offline (value -1)
            winners.push(keys[iterator].toUpperCase());
            while ((iterator + 1 < keys.length) && (data[keys[iterator]] == data[keys[iterator + 1]])) {
                winners.push(keys[iterator + 1].toUpperCase());
                iterator += 1;
            }
        }
        return winners;
    }

    createTabs(data, count) {
        let winners = []; let lossArr;
        if (Utils.isSet(this.props.selectedIp)) {
            winners = this.sortAndFindTheBest(this.props.colorList[this.props.selectedIp]);
            lossArr = Utils.isSet(this.props.colorList) ? this.props.colorList[this.props.selectedIp] : undefined;
        }
        const probes = [];
        const { activeProbe } = this.state;
        let firstLet = '_';
        data.forEach((probe) => {
            probe = probe.toUpperCase();
            const letter = probe[0];
            const loss = lossArr == undefined ? undefined : lossArr[probe];
            const color = Utils.getLossColor(loss);
            const isWinner = winners.includes(probe);
            let addClass = activeProbe == probe ? ' active' : '';
            if (isWinner) {
                addClass += ' winner';
            }
            const inside = [];
            const hoverClass = probe.length > 12 ? ' hover-text' : '';
            if (count > 10 && firstLet != letter) {
                inside.push(
                    <span key={probe} className={`probe-wrap ${hoverClass}`}>
                        <span className={`first-letter${addClass}`}>{letter}</span>
                        <span className={addClass} data-value={probe}>{probe.substring(1)}</span>
                    </span>,
                );
            } else {
                inside.push(
                    <span key={probe} className={`probe-wrap ${hoverClass}`}>
                        <span className={addClass} data-value={probe}>{probe}</span>
                    </span>,
                );
            }
            let icon = (<svg><circle cx='5' cy='5' r='4' style={{ fill: color }} /></svg>);
            if (isWinner) {
                icon = (<i className='fa fa-trophy' style={{ color }} />);
            }
            probes.push(
                <li key={probe} className={`values${addClass}`} data-value={probe} onClick={this.probeChange}>
                    {icon}
                    {inside}
                </li>,
            );
            firstLet = letter;
        });
        return probes;
    }

    probeChangeSelect(e) {
        const probe = e.target.value;
        const probeDesc = `slave=${probe}`;
        this.props.setActiveProbe(probeDesc);
    }

    probeChange(e) {
        const probe = e.target.getAttribute('data-value');
        this.setState({
            activeProbe: probe,
        });
        this.props.setActiveProbe(`slave=${probe}`);
    }

    getShowAllLink() {
        if (this.props.probesList.length > 1 && this.props.handleDetailSwitch != undefined && !this.props.isMobile) {
            return (
                <li key='showAll' className='values showall' value='showAll' onClick={this.showAllProbes}>
                    <i className='fa fa-list' />
                </li>
            );
        }
    }

    sideButtonAction() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    updateDimensions() {
        let width = Utils.getProbeWidth(false, this.props.isNarrow);
        width = width > 1500 ? 1500 : width;
        this.setState({ width });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state != nextState) {
            return true;
        }
        if (this.props.probesList != nextProps.probesList
            || this.props.colorList != nextProps.colorList) {
            return true;
        }
        if (this.props.activeProbe != nextProps.activeProbe) {
            this.setState({ activeProbe: nextProps.activeProbe.split('=')[1] });
        }
        return false;
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const count = Object.keys(this.props.probesList).length + 1;
        const fitCount = this.state.width / 170; // 170 - is probe <li> width
        const lineCount = Math.ceil(count / fitCount);
        let height = lineCount * 33; // 33 is line height
        let classProbe = height <= 33 ? 'row' : 'column';
        if (this.props.isMobile) {
            height = 45;
            classProbe = 'row';
        }
        const chevronClass = this.state.isOpen ? 'up' : 'down';
        const style = { height: `${height}px` };
        return (
            <div className={`probe-block ${classProbe}${this.state.isOpen ? '' : ' closed'}`}>
                <ul ref='probesUl' className={`probes-ul${count > 10 ? ' long-list' : ''}`} style={style}>
                    {this.createProbes()}
                    {/* {this.getShowAllLink()} */}
                </ul>
                {
                    (lineCount > 1 && !this.props.isMobile)
                    && (
                        <div className='side-btn-wrap'>
                            <a className='side-action-button' onClick={this.sideButtonAction}>
                                <SVGInline svg={iconChevronDown} className={`chevron ${chevronClass}`} />
                            </a>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default GraphProbe;
