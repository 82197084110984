/* global $:true, Chart:true */
import Utils from './utils.js';

var DataAdvanced = window.DataAdvanced = {

    colorField: Chart.colorField,
    colorShadeConstant: 7,

    loadData(ip, now, epoch, count, probe, callback, rep = 1) {
        const from = now - epoch;
        count = count == undefined || Number.isNaN(count) || count < 30 ? 30 : count;
        const url = `/grf/${count}/${ip}/${from}/${now}?${probe}&shades=${DataAdvanced.colorShadeConstant}`;

        // ----------------- API CALL GET -----------------
        const scope = this;
        const successCallback = function (dataO) {
            let data = dataO;
            if (Utils.isSet(dataO.error)) {
                data = { data: [] };
            }
            let called = false;
            const dataLen = Object.keys(data.data).length;
            // When there are no data the request is repeated once to ensure it was not an api error
            if (dataLen < 2 && rep > 0) {
                scope.loadData(ip, now, epoch, count, probe, callback, 0);
                called = true;
            }
            if (!called) {
                data = dataLen < 2 && rep == 0 ? { data: {}, prompt: dataO.error } : data;
                if (dataLen == 1) {
                    data.prompt = 'Collecting data...';
                }
                let stats;
                if (Utils.isSet(data) && dataLen > 2) {
                    stats = {
                        count: {
                            count,
                            total: data.info['total-count'],
                            lost: data.info['total-count-lost'],
                        },
                        loss: data.info['packet-loss'],
                    };
                }
                const chartData = scope.processData(data);
                callback(dataO.data, chartData, now, stats);
            }
        };
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    },

    getDisplayData(data, reverse = true) {
        if (!Utils.isSet(data.data)) {
            return data;
        }
        const formedData = this.formData(data.data, reverse);
        return {
            data: formedData,
            packets: data.packets,
            latency: data.latency,
            yaxis: data.yaxis,
            stats: data.stats,
            lineData: data.lineData,
        };
    },

    getLineData(data) {
        const objectData = data.data;
        const stamps = Object.keys(objectData);
        const arr = [];
        for (const index in stamps) {
            const timestamp = stamps[index];
            const val = objectData[timestamp].loss < 100 ? objectData[timestamp].med : null;
            arr.push([Number(timestamp) * 1000, val]);
        }
        return arr;
    },

    processData(data) {
        if (!Utils.isSet(data.data)) {
            return data;
        }
        const shadingsNum = DataAdvanced.colorShadeConstant;
        const min = [];
        const max = [];
        const zero = [];
        const avg = [[], [], [], [], [], [], []];
        for (let i = 0; i < shadingsNum; i++) {
            min.push([]);
            max.push([]);
        }
        let x;
        var info = data.info['latency-info'];
        const limit = this.findYaxisMax(data);
        let avgHeight = limit * 0.01;
        avgHeight = avgHeight == 0 ? 0.01 : avgHeight;
        const stats = {
            0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [],
        };
        /*
        * PL color is constructed :
        * Math.ceil(loss/10)
        * PL_GROUP -> PL_VALUE_RANGE
        * 0% -> 0% PL
        * 10% -> 1% - 10% PL
        * 20% -> 11% - 20% PL
        * 30% -> 21% - 30% PL
        * 40% -> 31% - 40% PL
        * 50% -> 41% - 50% PL
        * 60%+ -> 51% - 100% PL
        * */
        for (const key in data.data) {
            var info = data.data[key];
            let colorIndex = info.loss / 10;
            colorIndex = Math.ceil(colorIndex);
            colorIndex = colorIndex >= this.colorField.length ? this.colorField.length - 1 : colorIndex;
            x = Number(key * 1000);
            zero.push([x, Number(info.blank)]);
            stats[colorIndex].push(x);

            for (let i = 0; i < shadingsNum; i++) {
                min[i].push([x, Number(info.min[i])]);
                max[i].push([x, Number(info.max[i])]);
            }
            // -------- Init avg part ------------
            for (let i = 0; i < avg.length; i++) {
                const y = colorIndex == i && info.loss < 100 ? avgHeight : 0;
                avg[i].push([x, Number(y)]);
            }
        }

        return {
            yaxis: limit,
            data: {
                max, avg, min, zero,
            },
            packets: {
                total_loss: data.info['total-count-lost'],
                total: data.info['total-count'],
                avg_loss: data.info['packet-loss'].avg,
                max_loss: data.info['packet-loss'].max,
            },
            latency: {
                avg: data.info['latency-info'].avg,
                min: data.info['latency-info'].min,
                max: data.info['latency-info'].max,
            },
            lineData: this.getLineData(data),
            stats,
        };
    },

    findYaxisMax(data) {
    // Find the ideal height to limit Y-Axis to show the data in the best
        const values = Object.values(data.data);
        let medians = values.map(o => o.med);
        medians.sort((a, b) => a - b);
        let i = 0;
        while (medians[i] <= 0) {
            i++;
        } // find first non zero median value
        medians = medians.splice(i); // delete all zero-value medians
        let index = Math.floor(medians.length * 0.90); // set to 90% percentil
        for (let iter = index + 1; iter < medians.length; iter++) {
            const value = medians[iter - 1];
            const nextValue = medians[iter];
            if (value * 1.3 >= nextValue) {
                // iterate indexed value by checking if the next value is a gradual change or a peak (using 30% change)
                index = iter;
            } else {
                break;
            } // exit loop if next value is bigger by 30% - assuming a peak
        }
        return medians[index] * 1.3; // 30% additional - to se above the last median value
    },

    formData(input, reverse) {
        const colorTemplate = 'rgba(C,C,C, 0.8)';
        const startColor = 70;
        const endColor = 200;
        let colorStep = (endColor - startColor) / DataAdvanced.colorShadeConstant;
        colorStep = colorStep.toFixed(0);
        const shadesField = [];
        for (let i = 0; i < DataAdvanced.colorShadeConstant; i++) {
            const col = startColor + (i * colorStep);
            const temp = colorTemplate.replace(/C/g, col);
            shadesField.push(temp);
        }
        if (reverse) {
            shadesField.reverse();
        }

        const grayColorsMin = shadesField;
        const grayColorsMax = shadesField.slice(0).reverse();

        // var grayColorsMin = ['rgba(49, 61, 73, 0.7)', 'rgba(56, 64, 73, 0.7)', 'rgba(89, 97, 108, 0.7)'];
        // var grayColorsMax =  ['rgba(89, 97, 108, 0.7)', 'rgba(56, 64, 73, 0.7)', 'rgba(49, 61, 73, 0.7)'];

        const avgColors = this.colorField;
        const data = [];
        const shadingsNum = DataAdvanced.colorShadeConstant;
        let i; let series;
        let values;

        // USING MEDIAN VALUES !!!!
        values = input.max;
        for (i = shadingsNum - 1; i >= 0; i--) { // maxium
            series = { data: values[i], color: grayColorsMax[i], type: 'column' };
            data.push(series);
        }

        values = input.avg;
        for (let j = 0; j < avgColors.length; j++) { // average
            series = {
                data: values[j], color: avgColors[j], type: 'column', zIndex: 2,
            };
            data.push(series);
        }

        values = input.min;
        for (i = shadingsNum - 1; i >= 0; i--) { // minimum
            series = { data: values[i], color: grayColorsMin[i], type: 'column' };
            data.push(series);
        }

        values = input.zero;
        data.push({ data: values, color: 'rgba(0, 0, 0, 0)', type: 'column' });
        return data;
    },

};
