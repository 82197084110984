import React from 'react';
import Utils from "../../js/utils";
import { Redirect } from 'react-router-dom';

window.activeUrl = '';

const UrlWrapper = (props) => {
    const generateUrl = (ip, graph, customEpoch) => {
        let params = `?ip=${ip}&`;
        if (!customEpoch) {
            params += `epoch=${graph.epoch}&`;
            params += `probe=${graph.probe.split('=')[1]}&`;
            params += `graphType=${graph.graphType}`;
        } else {
            params += `end=${graph.endPoint}&`;
            params += `epoch=${graph.epoch}&`;
            params += `probe=${graph.probe.split('=')[1]}&`;
            params += `graphType=${graph.graphType}`;
        }
        let { asNum } = props.state;
        let title = Utils.getPropertyOfObject(['dictionary', asNum], props, '');
        title = title .toLowerCase();
        title = title.replace(/\ /g,'-');
        let section = (asNum != null) ? '/' + asNum + '-' + title: 'AS-err'; // == to match null and also undefined
        return { section, params };
    };

    const fixMissingProperties = (graph) => {
        if (Utils.isNotSet(graph.probe)) { graph.probe = `slave=FRA`; } // WARNING !!! hardcoded fix
        if (Utils.isNotSet(graph.endPoint)) { graph.endPoint = Utils.getTimeNow(); }
        if (Utils.isNotSet(graph.graphType)) { graph.graphType = 'basic'; }
        if (Utils.isNotSet(graph.accuracy)) { graph.accuracy = 'normal'; }
        if (Utils.isNotSet(graph.epoch)) { graph.epoch = 86400; }
        if (graph.epoch < 60) { graph.epoch = 60; }
        return graph;
    }
    const { isMobile } = props;
    const ip = Utils.getPropertyOfObject(['state', 'selectedIp'], props, '');
    const graph = Utils.getPropertyOfObject(['state', 'ipDetails'], props, {});
    const customEpoch = Utils.getPropertyOfObject(['state', 'ipDetails', 'customEpoch'], props, false);
    const params = fixMissingProperties(graph);
    const url = generateUrl(ip, params, customEpoch);
    window.activeUrl = url.section+''+url.params;
    // --- all possible formats:
    // 1)... /AS123
    // 2)... /AS123-AS-NAME
    // 3)... /AS123?...ipdetails..
    // 1&3 from paste
    const { location } = window;
    // redidect conditions
    const cond1 = Utils.isNotSet(location.pathname); // no url is set, visitor came to hp
    const cond2 = Utils.isSet(location.search); // custom url has been used. strip that away, but show content specific to this url
    const cond3 = Utils.isSet(location.pathname) && !location.pathname.includes('-');// url is set but only AS-param no name, add name from dictionary
    if( cond1 || cond2 || cond3 ){
        return (<Redirect to={url.section}/>);
    }
    return (
        <div id='mother' className={isMobile ? 'mobile' : ''}>
            {props.children}
        </div>
    );
};

export default UrlWrapper;