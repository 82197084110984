/* eslint-disable */
import 'react-tippy/dist/tippy.css';
import '../../less/main.less';

import "@babel/polyfill";
import React from 'react';
import ReactDOM from "react-dom";
import Route from './route.js';

const Cross = window.Cross = {
    init() {
        ReactDOM.render(
            <Route />,
            document.getElementById('routeContent')
        );
    },
};
