import React from 'react';
import Utils from '../../../js/utils.js';
import ColorLegend from './colorLegend.jsx';

class GraphFooter extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getStats = this.getStats.bind(this);
        this.getColorLegend = this.getColorLegend.bind(this);
    }

    getStats() {
        const stats = [];
        if (Utils.isSet(this.props.graphData)) {
            const data = this.props.graphData.latency;
            if (Utils.isSet(data)) {
                stats.push(
                    <span key='0' className='span-info latency-max'>
                        MAX:
                        <span className='val'>
                            {data.max}
                            ms
                        </span>
                    </span>,
                );
                stats.push(
                    <span key='1' className='span-info latency-min'>
                        MIN:
                        <span className='val'>
                            {data.min}
                            ms
                        </span>
                    </span>,
                );
            }
        }
        if (Utils.isSet(this.props.graphData)) {
            const data = this.props.graphData.packets;
            if (Utils.isSet(data)) {
                let losInPerc = data.total > 0 && data.total_loss > 0 ? Number(data.total_loss / (data.total / 100)) : 0;
                losInPerc = losInPerc.toFixed(2);
                const colorLoss = Utils.getLossColor(losInPerc);
                const style = { color: colorLoss };
                stats.push(
                    <span key='2' className='span-info packet-total'>
                        TOTAL:
                        <span className='val'>{Utils.formatNumber(data.total, 0)}</span>
                    </span>,
                );
                stats.push(
                    <span key='3' className='span-info packet-total'>
                        LOSS:
                        <span className='val'>{Utils.formatNumber(data.total_loss, 0)}</span>
                    </span>,
                );
                stats.push(
                    <span key='4' className='span-info packet-total'>
                        <span className='val' style={style}>{`${losInPerc}%`}</span>
                    </span>,
                );
            }
        }
        return (
            <div className='footer-block stat-block'>
                {stats}
            </div>
        );
    }

    getColorLegend() {
        if (this.props.isMobile) {
            return;
        }
        return (
            <div className='footer-block legend-block'>
                <ColorLegend
                    graphData={this.props.graphData}
                    stats={this.props.stats}
                    pxWidth={this.props.pxWidth}
                    chart={this.props.chart}
                    graphType={this.props.ipDetails.graphType}
                    accuracy={this.props.ipDetails.accuracy}
                />
            </div>
        );
    }

    render() {
        return (
            <div className='footer'>
                {this.getColorLegend()}
                {this.getStats()}
            </div>
        );
    }
}

export default GraphFooter;
