import React from 'react';

const SettingsBox = (props) => {
    const setLightTheme = () => props.changeColor('light');
    const setDarkTheme = () => props.changeColor('dark');
    const setTheme = () => {
        if (props.theme.label === 'light') {
            setDarkTheme();
        } else {
            setLightTheme();
        }
    }
    let isLight = props.theme.label === 'light';

    return (
        <div className="settings-box w-150">
            <div className='switch'>
                <span className={isLight ? 'active' : ''} onClick={setDarkTheme}>
                    Dark
                </span>
                <label className='switch-slider'>
                    <input type='checkbox' onChange={setTheme} checked={isLight} />
                    <span className='slider round' />
                </label>
                <span className={isLight ? '' : 'active'} onClick={setLightTheme}>
                    Light
                </span>
            </div>
        </div>
    );
}

export default SettingsBox;
