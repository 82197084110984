import React from 'react';
import GraphProbe from './probes.jsx';
import EpochPicker from './epochPicker.jsx';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils.js';
import MobileSearch from "../../sidePanel/sidebar/mobileSearchWrapper.jsx";


class GraphHeaderMobile extends React.Component {
    constructor(props) {
        super(props);
    }

    copyASAction = (e) => {
        const scope = this;
        const selector = $(this.refs['as-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.asNum);
            selector.css('color', color);
        }, 2000);
    }

    componentDidMount() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        const probeslist = this.props.probesList;
        const asNum = Utils.getPropertyOfObject(['asNum'], this.props, '');
        const asTitle = Utils.getPropertyOfObject(['asTitle'], this.props, '');
        return (
            <div className='header mobile'>
                <div className='top-part-header mobile'>
                    <div className='top-line-block'>
                        <a className='home' href='/'>
                            <img alt='cocopacket-logo' id='icon-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                        </a>
                    </div>
                    <div className='control-block'>
                        <MobileSearch {...this.props}/>
                    </div>
                </div>
                <div className='content-block'>
                    <a onClick={this.copyASAction}>
                        <span
                            ref='as-title'
                            data-clipboard-text={this.props.asNum}
                            className={`ip ip-info copy-btn `}>
                            {asNum + (Utils.isSet(asTitle) ? '-' + asTitle : '')}
                        </span>
                    </a>
                </div>
                <div className='content-block'>
                    <a onClick={() => this.props.switchView()}>
                        <span>show info</span>
                    </a>
                </div>
                <GraphProbe
                    activeProbe={this.props.ipDetails.probe}
                    {...this.props}
                    probesList={probeslist}
                />
                <EpochPicker
                    epoch={this.props.ipDetails.epoch}
                    setEpochWithEndpoint={this.props.setEpochWithEndpoint}
                    isMobile={this.props.isMobile}
                />
            </div>
        );
    }
}

export default GraphHeaderMobile;
