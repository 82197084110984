/* global $:true */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import Main from '../react-js/main.jsx';
import 'signals';
import Utils from './utils.js';
import {Helmet} from 'react-helmet';

class Router extends React.PureComponent {
    render() {
        const isMobile = (window.innerWidth <= 800 && window.innerHeight <= 900) || window.innerWidth <= 600;
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path="/:asparam"
                        render={(match) => {
                            const asParam = match.match.params.asparam.toUpperCase();
                            const additionalParams = { isMobile };
                            let ASTitle = '';
                            let showCanonical = false;
                            if (asParam.startsWith('AS')) {
                                const paramArr = asParam.split('-');
                                const asNum = paramArr[0];
                                ASTitle = ' - ' + paramArr.slice(1).join('-');
                                showCanonical = (asParam.length > 1);
                                const parsed = queryString.parse(match.location.search);
                                const redirect = match.location.state;
                                if (Utils.isSet(parsed)) {
                                    showCanonical = true;
                                    const info = {
                                        epoch: parsed.epoch,
                                        probe: `slave=${parsed.probe}`,
                                        graphType: parsed.graphType,
                                        endPoint: (parsed.end != null) ? parsed.end : Utils.getTimeNow()
                                    };
                                    additionalParams.selectedIp = parsed.ip;
                                    additionalParams.graph = info;
                                }
                                additionalParams.asNum = asNum;
                                additionalParams.isRedirect= (Utils.isSet(redirect)) ? redirect.redirect : false;
                            }
                            return (
                                <Main {...additionalParams} >
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title>{additionalParams.asNum + ASTitle + ' | Cocopacket.com' }</title>
                                        <meta name='og:title' content={additionalParams.asNum} />
                                        <meta name='og:type' content={'website'}/>
                                        <meta name='og:url' content={'https://as.cocopacket.com/' + asParam.split('?')[0] } />
                                        {showCanonical &&
                                            <link rel="canonical" href={'https://as.cocopacket.com/' + asParam.split('?')[0] } />
                                        }
                                    </Helmet>
                                </Main>
                            );
                        }}
                    />
                    <Route path="/" render={() => ( <Main isMobile={isMobile}/> )} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;
