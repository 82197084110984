import React from 'react';
import Utils from "../../js/utils";
import Loader from './loader.jsx';
import { Link } from 'react-router-dom';
import HeaderMobile from "./header/infoHeaderMobile.jsx";
import Helmet from 'react-helmet';
import CountryList from '../../js/libs/countries.json';

class ASInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showDown: true,
            showUp: true,
            filter: '',
            showName: true,
        }
    }

    loadData = (as) => {
        if(as){
            const asNum = as.split('AS')[1];
            const successCallback = (data) => {
                const down = Utils.getPropertyOfObject(['downstreams'], data, []);
                const up = Utils.getPropertyOfObject(['upstreams'], data, []);
                this.setState({
                    data,
                    showDown: Object.keys(down).length < 100,
                    showUp: Object.keys(up).length < 100
                });
            }
            const data = Utils.formApiData('/asinfo/' + asNum, true, 'json', successCallback);
            Utils.performGETcall(data);
        }
    }

    componentDidMount() {
        const as = Utils.getPropertyOfObject(['asNum'], this.props);
        this.loadData(as);
    }

    isAsIn = (as) => {
        const asList = Utils.getPropertyOfObject(['asList'], this.props, []);
        return asList.includes('AS'+as);
    }

    getNeighbors = (title, data, stateKey) => {
        const sorted = Object.keys(data).sort((a,b) => Number(a) - Number(b));
        const filter = Utils.getPropertyOfObject(['filter'], this.state, '');
        const showName = Utils.getPropertyOfObject(['showName'], this.state);
        const getList = () => {
            const out = [];
            sorted.map(as => {
                const isIn = this.isAsIn(as);
                const completeAs = 'AS'+as;
                const name = data[as].name;
                const forSearch = (showName) ? completeAs + ' ' + name : completeAs;
                if(forSearch.toLowerCase().includes(filter.toLowerCase())){
                    out.push(
                        <Link key={as}
                              to={'/'+completeAs}
                              className={'AS-neighbors__item ' + (isIn ? 'AS-neighbors__item--isin' : '') + (showName ? ' AS-neighbors__item--long' : '')}>
                               <span>{completeAs}</span>
                              {(showName && name) && <span className={'AS-neighbors__item-name'}>{name}</span>}
                        </Link>
                    );
                }
            });
            return (out.length > 0 ) ? out : (<span className={'no-result'}>no connections</span>);
        };
        const isOpen = this.state[stateKey] ;
        const theList = (isOpen) ? getList() : sorted;
        const count = (Array.isArray(theList) ? theList.length : 0);
        return (
            <div className={'AS-neighbors'} key={title}>
                <div className={'AS-neighbors__top'}>
                    <h3>{title}</h3>
                    <span className={'count'}>[ {count} ]</span>
                    <a onClick={() => this.setState(prev => ({[stateKey]: !prev[stateKey]}))}>
                        <i className={'fa fa-chevron-' + (isOpen ? 'down' : 'up' )} />
                    </a>
                </div>
                {isOpen &&
                    <div className={'AS-neighbors__list AS-neighbors__list--' + (isOpen ? 'open' : 'close') }>{theList}</div>
                }
            </div>
        );
    }

    getDownStreams = (data) => {
        const title = 'Downstreams';
        const asData = Utils.getPropertyOfObject(['downstreams'], data, []);
        return this.getNeighbors(title, asData, 'showDown');
    }

    getUpStreams = (data) => {
        const title = 'Upstreams';
        const asData = Utils.getPropertyOfObject(['upstreams'], data, []);
        return this.getNeighbors(title, asData, 'showUp');
    }

    getStats = (as, data) => {
        const down = Utils.getPropertyOfObject(['downstreams'], data, []);
        const downCount = Object.keys(down).length;
        const up = Utils.getPropertyOfObject(['upstreams'], data, []);
        const upCount = Object.keys(up).length;
        const name = Utils.getPropertyOfObject(['name'], data, '');
        const countryAbbrv = Utils.getPropertyOfObject(['country'], data, '');
        const country = Utils.getPropertyOfObject([countryAbbrv], CountryList, countryAbbrv);
        const description = Utils.getPropertyOfObject(['desc'], data, '');
        const metaDescription = `${as} - ${description} is located in ${country}, and it has
                ${upCount} upstreams and ${downCount} downstreams`;
        return (
            <>
                <Helmet>
                    <meta name='description' content={metaDescription} />
                    <meta name='og:description' content={metaDescription} />
                </Helmet>
                <div className={'stats'}>
                    <h3>Info</h3>
                    <div className={'stats__list'}>
                        <span className={'stats__item'}> Name: <span>{name}</span></span>
                        <span className={'stats__item'}> Country: <span>{country + ' | ' + countryAbbrv}</span></span>
                        <span className={'stats__item stats__item--desc'}> Description: <span>{description}</span></span>
                        <span className={'stats__item'}> UpStream count: <span>{upCount}</span></span>
                        <span className={'stats__item'}> Downstream count: <span>{downCount}</span></span>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const data = Utils.getPropertyOfObject(['data'], this.state);
        const as = Utils.getPropertyOfObject(['asNum'], this.props);
        const showName = Utils.getPropertyOfObject(['showName'], this.state);
        if(Utils.isNotSet(data)){
            return (
                <div className="as-info__panel as-info__load">
                    <Loader/>
                    <h2>Loading info about {as}...</h2>
                </div>
            );
        }
        let imageIndex = Math.floor(Math.random()*2);
        imageIndex = (imageIndex === 0) ? 3 : imageIndex;
        const isMobile = Utils.getPropertyOfObject(['isMobile'], this.props, false);
        return (
            <>
                {isMobile && <HeaderMobile {...this.props} />}
                <div className="as-info__panel">
                    <div className={'as-info__block--row'}>
                        <div className={'as-info__border as-info__border--half'}>
                            {this.getStats(as, data)}
                        </div>
                        {!isMobile &&
                            <div className={'as-info__border as-info__border--half as-info__border--half-img'}>
                                <a onClick={this.props.switchView}>
                                    <img src={'/less/img/graphs/graph_2.png'}/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className={'as-info__border'}>
                        <div className="search">
                            <input
                                type="text"
                                onChange={e => this.setState({ filter : e.target.value})}
                                placeholder={'Filter AS streams...'}
                            />
                            <a onClick={() => this.setState(prev => ({showName: !prev.showName}))}>
                                {(showName ? 'Hide' : 'Show') + ' names'}
                            </a>
                        </div>
                        <div className={'as-info__block'}>
                            {this.getUpStreams(data)}
                        </div>
                        <div className={'as-info__block'}>
                            {this.getDownStreams(data)}
                        </div>
                    </div>
                    <div className={'as-info__border'}>
                        <a href={'https://radar.tools.cdn77.com/#'+as}
                           target='_blank'
                           rel='noreferrer noopener'
                           className={'radar-link'}
                        >Visit our radar-tool to get pingable IPs for {as} </a>
                    </div>
                </div>
            </>
        );
    }
}

export default ASInfo;
