/* global Chart:true */
import React from 'react';
import Utils from '../../../js/utils.js';
import {Link} from 'react-router-dom';

class AsPath extends React.PureComponent {
    getAsPoints = () => {
        const colors = Chart.colorField;
        const asArray = [];
        const { asPath } = this.props;
        asPath.forEach((as, i) => {
            let colorIndex = as.pl / 10;
            colorIndex = Math.ceil(colorIndex);
            colorIndex = colorIndex >= colors.length ? colors.length - 1 : colorIndex;
            const style = { color: colors[colorIndex], border: `solid 1px ${colors[colorIndex]}` };
            const color = { color: colors[colorIndex] };
            const asNum = as.name == -1 ? '???' : as.name;
            asArray.push(
                <li key={i} style={style}>
                    <Link to={'/AS'+asNum} style={color}>
                        {asNum}
                    </Link>
                </li>
            );
        });
        return asArray;
    }

    render() {
        return (
            <div className='aspath'>
                <span className='title'>LAST AS PATH: </span>
                <ul>
                    {Utils.isSet(this.props.asPath) && this.getAsPoints()}
                </ul>
            </div>
        );
    }
}

export default AsPath;
