import React from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../../js/utils.js';

const As = (props) =>{

    const getHighestLossColor = () => {
        // console.log(props);
        const list = Utils.getPropertyOfObject(['colorList'], props, []);
        const ips = Utils.getPropertyOfObject(['ips'], props, []);
        if(Utils.isSet(list) && Utils.isSet(ips)){
            let value = 0;
            ips.forEach((ip) => {
                value += Utils.getPropertyOfObject([ip, 'AVERAGE'], list, 0);
            });
            return Utils.getLossColor(value);
        }
        return Utils.defColor;
    };

    const getContent = () => {
        const switchedContent = Utils.getPropertyOfObject(['switchedContent'], props, false);
        if(switchedContent){
            return (
                <span className={'title ' + slideClass} >
                    <span className={'svg__wrap'}>
                        <svg><rect cx='5' cy='5' r='3' style={getCategoryMark()} /></svg>
                    </span>
                    <span>
                        {as.toUpperCase()}
                    </span>
                </span>
            );
        } else {
            return (
                <span className={'title ' + slideClass} >
                    {as.toUpperCase()}
                    {/*<svg>*/}
                    {/*    <circle cx='5' cy='5' r='3' style={getCategoryMark()} />*/}
                    {/*</svg>*/}
                </span>
            )
        }
    };
    // const getCategoryMark = () => ({ fill: getHighestLossColor() });
    const as = Utils.getPropertyOfObject(['asTitle'], props, '');
    const asUrl = as.replace(/\ /g, '-');
    const slideClass = (as.length > 25) ? 'slide-text' : '';
    return (
        <li className='ip-li'>
            <div className='first-line'>
                <Link to={"/" + asUrl} className='category-section-name'
                      onClick={(Utils.isSet(props.asOnClick))?props.asOnClick:()=>{}}
                >
                    <span className='section'>
                        {getContent()}
                    </span>
                </Link>
            </div>
        </li>
    );
}

export default As;
