import React, {useState} from 'react';
import Search from './search.jsx';
import Utils from "../../../js/utils";
import As from "./as.jsx";


const MobileSearch = (props) => {
    const [showList, setShowList] = useState(false);
    const [filtered, setFiltered] = useState(null);
    const data = Utils.getPropertyOfObject(['config','groups'], props, []);
    const source = (filtered !== null) ? filtered : Object.keys(data);
    const keys = source.map(key => key.split('->')[0]) ;
    const content = [];
    keys.forEach((key, i) => {
        const as = key.split('-')[0];
        let title = key.toLowerCase();
        title = title.replace(/^as{1}/, 'AS');
        const ips = Utils.getPropertyOfObject(['structure',as], props, []);
        if(ips.length === 0) {
            return;
        }
        content.push(
            <As
                key={as+'_'+i}
                ips={props.structure[as]}
                as={as}
                asTitle={title}
                colorList={props.colorList}
                switchedContent={true}
                asOnClick={()=>{setShowList(false)}}
            />
        );
    });
    if(content.length === 0){ content.push(<li key="no-data" className={'ip-li'}>no results</li>); }
    return (
        <div className="mobileSearch__wrap">
            <div id={'sidebar--mobile'}>
                <Search
                    data={data}
                    setFiltered={setFiltered}
                    searchOnFocus={()=>setShowList(true)}
                />
                {showList &&
                    <ul className='mobileSearch__list'>
                        <li className={'ip-li__close'}>
                            <a onClick={()=>setShowList(false)}>
                                <span className={'title'} >
                                    <span> close search </span>
                                </span>
                            </a>
                        </li>
                        {content}
                    </ul>
                }
            </div>
        </div>
    );
}

export default MobileSearch;
