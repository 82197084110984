/* global $:true */
import React from 'react';
import Clipboard from 'clipboard';
import SettingsBox from '../../common/settingsBox.jsx';
import Utils from '../../../js/utils.js';

class ControlPanel extends React.Component {

    copyIPAction = (e) => {
        const scope = this;
        const selector = $(this.refs['ip-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.selectedIp);
            selector.css('color', color);
        }, 2000);
    }

    copyASAction = (e) => {
        const scope = this;
        const selector = $(this.refs['as-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.asNum);
            selector.css('color', color);
        }, 2000);
    }

    switchIp = (iter) => {
        const { asNum, selectedIp } = this.props;
        const arr = Utils.getPropertyOfObject(['structure', asNum], this.props);
        const index = arr.indexOf(selectedIp);
        let newIndex = (index+iter)%arr.length;
        newIndex += (newIndex < 0) ? arr.length : 0;
        const newIp = arr[newIndex];
        this.props.updateIp(newIp);
    }

    componentDidUpdate() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        return (
            <div className="control-panel-wrap safari-flex-fix">
                <div className="control-panel">
                    <div className='title-wrap'>
                        <a className={'title-wrap__move'} onClick={() => this.switchIp(-1)}>
                            <i className={"fa fa-chevron-left"} />
                        </a>
                        <a onClick={this.copyIPAction}>
                        <span
                            ref='ip-title'
                            data-clipboard-text={this.props.selectedIp}
                            className={`ip ip-info copy-btn`}>
                            {this.props.selectedIp}
                        </span>
                        </a>
                        <a className={'title-wrap__move'} onClick={() => this.switchIp(1)}>
                            <i className={"fa fa-chevron-right"} />
                        </a>
                    </div>
                </div>
                <SettingsBox theme={this.props.theme} changeColor={this.props.changeColor} />
            </div>
        );
    }
}

export default ControlPanel;
