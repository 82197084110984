import React from 'react';
import Utils from '../../../js/utils.js';

const Search = (props) => {
    const filter = (val) => {
        const { data } = props;
        const filtered = [];
        Object.keys(data).forEach((key) => {
           const keyL = key.toLowerCase();
           if(keyL.includes(val.toLowerCase())){
               filtered.push(key);
           }
        });
        props.setFiltered(filtered);
    };
    return (
        <div className="search">
            <input
                type="text"
                onChange={e => filter(e.target.value)}
                placeholder={'Search...'}
                onFocus={Utils.isSet(props.searchOnFocus) ? props.searchOnFocus : ()=>{}}
            />
        </div>
    );
}

export default Search;
