import React from 'react';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils.js';
import GraphProbe from './probes.jsx';
import EpochPicker from './epochPicker.jsx';
import Matrix from '../matrix.jsx';

class GraphHeader extends React.Component {

    componentDidUpdate() {
        const clipboard = new Clipboard('.ip-copy-btn');
    }

    render() {
        const probeslist = this.props.probesList;
        const probeName = this.props.ipDetails.probe.split('=')[1];
        return (
            <div className='header'>
                <GraphProbe
                    activeProbe={this.props.ipDetails.probe}
                    selectedIp={this.props.selectedIp}
                    setActiveProbe={this.props.setActiveProbe}
                    probesList={probeslist}
                    colorList={this.props.colorList}
                    isMobile={this.props.isMobile}
                    isNarrow={this.props.isNarrow}
                />
                <div className='epoch-matrix-block'>
                    <EpochPicker
                        epoch={this.props.ipDetails.epoch}
                        setEpochWithEndpoint={this.props.setEpochWithEndpoint}
                        isMobile={this.props.isMobile}
                    />
                    <Matrix
                        ip={this.props.selectedIp}
                        activeProbe={probeName}
                        setActiveProbe={this.props.setActiveProbe}
                    />
                </div>
            </div>
        );
    }
}

export default GraphHeader;
